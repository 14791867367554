<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />
        
      </b-link>
  -->
      <!-- /Brand logo-->

      <!-- Left Text-->

      <!-- style="background-image: url('https://dk0pm9zdlq16s.cloudfront.net/862a7c99-cee2-44d7-a2db-a2c146250d70.png');"
       -->
      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img style="width: 25vw;" fluid :src="appLogoImage" alt="Login" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Authentication -->
      <b-col
        v-if="showOtp"
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Authentication
          </b-card-title>
          <b-card-text class="mb-2">
            We sent a verification code to your email
            {{ ulogin.email }} .<br />Enter the code below to contiune.
          </b-card-text>
          <b-row align-h="center">
            <b-col md="12" class="d-flex">
              <b-form-input
                v-for="(item, ind) in enteredCode"
                :key="ind"
                type="number"
                max="9"
                min="0"
                :no-wheel="true"
                :ref="'code' + ind"
                v-model="enteredCode[ind]"
                class="text-center mx-50"
                :disabled="otpLoading"
                @input="moveNext(ind)"
              />
            </b-col>
            <b-col md="6" class="mt-1">
              <b-button
                variant="primary"
                :disabled="verifyToggle || otpLoading"
                @click="verifyOtp()"
                block
              >
                <b-spinner
                  v-if="otpLoading"
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
                <span v-else>
                  Verify
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
      <!-- Login-->
      <b-col
        v-else
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Login
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to open admin portal.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent="mylogin">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="ulogin.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="hammad@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="ulogin.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-link
                  hidden
                  :to="{ name: 'auth-forgot-password-v2' }"
                  class="float-right"
                >
                  <small>Forgot Password?</small>
                </b-link>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group hidden>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="loading"
              >
                <!--  @click="testlogin()" -->
                <b-spinner
                  v-if="loading"
                  variant="light"
                  type="grow"
                  small
                  label="Spinning"
                ></b-spinner>

                <span v-if="!loading">Sign in</span>
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'form-seller' }" >
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import logo from "@/assets/images/logo/Geo-Talent logo.png";
import { $themeConfig } from "@themeConfig";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    //VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      logo,
      loading: false,
      username: "",
      role: "",
      vendorId: 0,
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/logo_large.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      ulogin: {
        email: "",
        password: "",
      },
      appLogoImage: $themeConfig.app.appLogoImage,
      otpLoading: false,
      showOtp: false,
      code: "",
      enteredCode: [],
      verifyToggle: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  // created() {
  //   let otpObj = this.$store.state.otpObj;
  //   console.log(otpObj);
  //   let dt = new Date("2024-10-18T11:24:00");
  //   const currentTime = new Date("2024-10-18T11:26:40");
  //   const timeDifference = currentTime - dt;
  //   const twoMinutesInMillis = 2 * 60 * 1000; // 2 minutes in milliseconds
  //   console.log(timeDifference, timeDifference >= twoMinutesInMillis);
  // },
  beforeDestroy() {
    if (this.code) {
      this.$store.commit("setOtpObj", {
        code: "",
        time: null,
        attempts: 0,
      });
    }
  },
  methods: {
    testlogin() {
      useJwt
        .login({
          email: "admin@demo.com",
          password: "admin",
        })
        .then((response) => {
          // console.log(response);
          const { userData } = response.data;
          useJwt.setToken(response.data.accessToken);
          useJwt.setRefreshToken(response.data.refreshToken);
          localStorage.setItem("userData", JSON.stringify(userData));

          this.$ability.update(userData.ability);

          this.$store.commit(
            "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
            userData.extras.eCommerceCartItemsCount
          );
          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          // this.$store.commit(
          //   'app-ecommerce/UPDATE_CART_ITEMS_COUNT',
          //   userData.extras.eCommerceCartItemsCount,
          // );
          //console.log("Osama");
          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease

          this.$router
            .replace(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Welcome Administrator`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully logged in as ${this.role}. Now you can start to explore!`,
                },
              });
            });
        })
        .catch((error) => {
          this.$refs.loginForm.setErrors(error.response.data.error);
        });
    },
    async sendOtp() {
      this.loading = true;
      this.code = `${Math.floor(1000 + Math.random() * 9000)}`;
      // console.log(this.code);
      this.$store.commit("setOtpObj", {
        code: this.code,
        time: new Date(),
        attempts: 0,
      });

      var axios = require("axios");
      const config = {
        method: "post",
        url: "https://mail.appick.io/cloudious",
        data: {
          emailTo: this.ulogin.email,
          subject: "World Appeal | Login Authentication",
          text: `Here is your verification code to access World Appeal | Admin\n${this.code}`,
          html: "",
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res);
          if (res.data.status == "success") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "OTP sent to your mail.",
                icon: "CheckCircleIcon",
                variant: "success",
                text: "Please verify to continue",
              },
            });
            this.enteredCode = new Array(4).fill(null);
            // console.log(this.code);
            this.verifyToggle = true;
            this.showOtp = true;
            setTimeout(() => {
              this.$refs["code0"][0].focus();
            }, 300);
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Something went wrong!",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: "Please try again later",
              },
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Something went wrong!",
              icon: "AlertCircleIcon",
              variant: "danger",
              text: "Please try again later",
            },
          });
        })
        .finally(() => (this.loading = false));
    },
    moveNext(ind) {
      // console.log(this.enteredCode);
      if (ind != this.enteredCode.length - 1 && this.enteredCode[ind]) {
        let elem = this.$refs[`code${ind + 1}`][0];
        elem.focus();
      }
      if (this.enteredCode.includes(null) || this.enteredCode.includes("")) {
        this.verifyToggle = true;
      } else this.verifyOtp();
    },
    async verifyOtp() {
      this.verifyToggle = true;
      this.otpLoading = true;

      setTimeout(() => {
        let check = this.enteredCode.join("");
        if (check == this.code) {
          // this.showOtp = false;
          // this.enteredCode = [];

          useJwt
            .login({
              email: "admin@demo.com",
              password: "admin",
            })
            .then((response) => {
              // console.log(response);
              const { userData } = response.data;
              useJwt.setToken(response.data.accessToken);
              useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem("userData", JSON.stringify(userData));

              this.$ability.update(userData.ability);

              this.$store.commit(
                "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
                userData.extras.eCommerceCartItemsCount
              );

              this.code = "";
              this.$store.commit("setOtpObj", {
                code: "",
                time: null,
                attempts: 0,
              });
              this.verifyToggle = false;
              this.otpLoading = false;
              this.$router
                .replace({
                  name: this.$store.state.pageRights[0].route,
                })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Welcome Administrator`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `You have successfully logged in as admin. Now you can start to explore!`,
                    },
                  });
                });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Something went wrong!",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: "Please try again later",
                },
              });
              this.verifyToggle = false;
              this.otpLoading = false;
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Login Falied!",
              icon: "AlertCircleIcon",
              variant: "danger",
              text: "OTP entered is incorrect.",
            },
          });
          this.verifyToggle = false;
          this.otpLoading = false;
        }
      }, 2500);
    },
    mylogin() {
      this.$refs.loginValidation.validate().then((success) => {
        // this.ulogin.email !== "" && this.ulogin.password !== ""
        if (success) {
          this.loading = true;
          var axios = require("axios");
          var data = this.ulogin;
          var mydata = {
            username: this.ulogin.email,
            password: this.ulogin.password,
          };
          // https://worldappeal.appick.io/api/
          var config = {
            method: "post",
            url: "https://api.worldappeal.org.uk/api/tokens/UserLogin",
            data: mydata,
          };

          axios(config)
            .then((response) => {
              //console.log(response.data);
              this.username = response.data.userName;
              this.role = response.data.role;
              if (
                response.data.data.role === "admin" ||
                response.data.data.role === "Admin"
              ) {
                if (response.data.data.status == "active") {
                  // console.log("MtData", response.data.data);
                  // console.log(this.$store.state.userData.userID);
                  this.$store.state.userData.token = response.data.token;
                  this.$store.state.userData.userID = response.data.data.id;

                  let rights = JSON.parse(response.data.data.userRights);
                  this.$store.commit("setRights", rights);
                  // console.log(this.$store.state.pageRights);

                  // console.log(this.$store.state.userData.token);
                  this.sendOtp();
                  return;
                  // login was here
                } else if (response.data.status == "pending") {
                  Swal.fire({
                    title: "Please wait..",
                    text: "Your account is in process for approval.",
                    icon: "info",
                    iconColor: "#FB595f",
                    confirmButtonColor: "#FB595f",
                  }).then((res) => {
                    this.loading = false;
                  });
                } else if (
                  response.data.status == "declined" ||
                  response.data.status == "suspend"
                ) {
                  console.log("declined");
                  Swal.fire({
                    title: "Failed!",
                    text: "Your account approval is suspend for some reasons.",
                    icon: "warning",
                    iconColor: "#FB595f",
                    confirmButtonColor: "#FB595f",
                  }).then((res) => {
                    this.loading = false;
                  });
                  this.loading = false;
                } else {
                  console.log("Error!");
                  Swal.fire({
                    title: "Error!",
                    text: "Please check you email or password is correct.",
                    icon: "error",
                    iconColor: "#FB595f",
                    confirmButtonColor: "#FB595f",
                  }).then((res) => {
                    this.loading = false;
                  });
                }
              } else {
                this.$bvToast.toast(
                  "Please check your email or password is correct.",
                  {
                    title: "Error!",
                    variant: "danger",
                    solid: true,
                  }
                );
                return (this.loading = false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.$bvToast.toast("Please fill the form correctly.", {
            title: "Error!",
            variant: "danger",
            solid: true,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
